/**
 * @generated SignedSource<<bd0dc603cb06a2e8eb899468620fcbe5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserAccountManagementQuery$variables = {
  after?: string | null;
  companyId: string;
  first: number;
  searchTerm?: string | null;
};
export type UserAccountManagementQuery$data = {
  readonly company: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAccountManagementCompanyFragment_company">;
  };
};
export type UserAccountManagementQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly accounts: {
      readonly __id?: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly companyAccountPermissions: {
            readonly payload: {
              readonly __typename: "AccountPermissions";
              readonly __isNode: "AccountPermissions";
              readonly id: string;
              readonly name: string;
            } | {
              readonly __typename: string;
              readonly __isNode: string;
              readonly id: string;
            } | null;
          } | null;
          readonly email: string;
          readonly firstName: string;
          readonly id: string;
          readonly lastName: string;
          readonly mfaPhoneNumberHint: string | null;
          readonly phone: string;
        };
      }>;
      readonly pageInfo: {
        readonly endCursor: string;
        readonly hasNextPage: boolean;
      };
    } | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type UserAccountManagementQuery = {
  rawResponse: UserAccountManagementQuery$rawResponse;
  response: UserAccountManagementQuery$data;
  variables: UserAccountManagementQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v7 = {
  "kind": "Variable",
  "name": "companyId",
  "variableName": "companyId"
},
v8 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v9 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAccountManagementQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "company",
          "args": (v4/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "args": [
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/)
              ],
              "kind": "FragmentSpread",
              "name": "UserAccountManagementCompanyFragment_company"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "company"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserAccountManagementQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  (v6/*: any*/),
                  {
                    "fields": [
                      (v9/*: any*/)
                    ],
                    "kind": "ObjectValue",
                    "name": "filter"
                  },
                  (v8/*: any*/)
                ],
                "concreteType": "AccountConnection",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "phone",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mfaPhoneNumberHint",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              (v7/*: any*/)
                            ],
                            "concreteType": "GetAccountPermissionsByCompanyAndAccountPayload",
                            "kind": "LinkedField",
                            "name": "companyAccountPermissions",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "payload",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "AccountPermissions",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v5/*: any*/)
                                    ],
                                    "type": "Node",
                                    "abstractKey": "__isNode"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5afd594a49b24264ee5c6d508428f6f7",
    "id": null,
    "metadata": {},
    "name": "UserAccountManagementQuery",
    "operationKind": "query",
    "text": "query UserAccountManagementQuery(\n  $companyId: ID!\n  $first: Int!\n  $after: String\n  $searchTerm: String\n) {\n  company: node(id: $companyId) {\n    __typename\n    id\n    ...UserAccountManagementCompanyFragment_company_1TCdwG\n  }\n}\n\nfragment AccountTableRowUIFragment_account_MWiJC on Account {\n  id\n  firstName\n  lastName\n  email\n  phone\n  mfaPhoneNumberHint\n  ...UserPermissionField_SettingsUI_Account_MWiJC\n}\n\nfragment UserAccountManagementCompanyFragment_company_1TCdwG on Company {\n  id\n  accounts(first: $first, after: $after, filter: {searchTerm: $searchTerm}) {\n    pageInfo {\n      ...usePaginatedDataFragment_pageInfo\n    }\n    edges {\n      node {\n        id\n        ...AccountTableRowUIFragment_account_MWiJC\n      }\n    }\n  }\n}\n\nfragment UserPermissionField_SettingsUI_Account_MWiJC on Account {\n  id\n  companyAccountPermissions(companyId: $companyId) {\n    payload {\n      __typename\n      ... on AccountPermissions {\n        __typename\n        id\n        name\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment usePaginatedDataFragment_pageInfo on PageInfo {\n  endCursor\n  hasNextPage\n}\n"
  }
};
})();

(node as any).hash = "36fe49f460a79e637a37a45680892449";

export default node;
